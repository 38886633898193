import $ from 'jquery';

/**
 * Generates the HTML structure for a user dropdown item.
 * It will display the username along with their fullname(more context about the user)
 * @param {Object} user - The user object containing user details.
 */
export function usersDropDownItemTemplate(user) {
  const $container = $(
    `<div>
      <div>
        <div class='select2-result-repository__title'></div>
        <div class='select2-result-repository__description'></div>
      </div>
    </div>`,
  );
  $container.find('.select2-result-repository__title').text(user.name);
  $container
    .find('.select2-result-repository__description')
    .text(user.username);
  return $container;
}

/**
 * Generates the HTML structure for the selected  user from the  dropdown.
 * It will display the username or fullname(more context about the user)
 * @param {Object} user - The user object containing user details.
 */
export function selectedUserTemplateDisplayFunc(user) {
  return user.name || user.username;
}

/**
 * Specifies the parameters sent along when requesting user data.
 * the term user for querying is "search"
 * Example: 127.0.0.1:8000/api/users/?search="John Doe".
 */
export function defaultRequestParamsFunc(params) {
  return { search: params.term || '' };
}

/**
 * Define how user response data is processed so that select2 understands.
 * Typical DRF response holds response  data in the results key
 * Along with it is the "count", "page"
  {
    "count": 2,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 1,
            "name": "Item 1",
            "description": "Description of Item 1",
            "created_at": "2024-09-02T10:00:00Z",
            "updated_at": "2024-09-02T10:00:00Z"
        },
    ]
  }

 */
export function defaultProcessResponseResultsFunc(data) {
  return { results: data.results || [] };
}
