import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import 'select2';
import configurations from '../constants/selectDropDownConfigs';

export default class EnableSelectTwo extends Controller {
  connect() {
    const identifier = this.element.dataset.identifier;
    const config = configurations[identifier];

    if (config) {
      if (!config.templateSelection || !config.templateResult) {
        console.error(
          `Configuration for identifier: ${identifier} must include both templateSelection and templateResult functions.`,
        );
        return;
      }
      this.initializeSelect(config);
    } else {
      console.error(`Configuration not found for identifier: ${identifier}`);
    }
  }
  /* istanbul ignore next */
  initializeSelect(config) {
    $(this.element).select2({
      theme: config.theme || 'bootstrap-5',
      closeOnSelect: config.closeOnSelect || false,
      ajax: {
        url: config.url,
        delay: config.delay || 250,
        dataType: 'json',
        data:
          config.data ||
          function (params) {
            return { search: params.term };
          },
        processResults:
          config.processResults ||
          function (data) {
            return { results: data.results };
          },
        cache: config.cache || true,
      },
      templateSelection: config.templateSelection,
      templateResult: config.templateResult,
      placeholder: config.placeholder || 'Searching',
      minimumInputLength: config.minimumInputLength || 1,
    });
  }
}
