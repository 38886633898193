import {
  usersDropDownItemTemplate,
  defaultRequestParamsFunc,
  defaultProcessResponseResultsFunc,
  selectedUserTemplateDisplayFunc,
} from '../helpers/selectDropDownConfigHelpers';

const configurations = {
  userSearch: {
    url: 'http://127.0.0.1:8000/api/users/',
    theme: 'bootstrap-5',
    closeOnSelect: false,
    data: defaultRequestParamsFunc,
    processResults: defaultProcessResponseResultsFunc,
    templateResult: usersDropDownItemTemplate,
    templateSelection: selectedUserTemplateDisplayFunc,
    placeholder: 'Search for users',
    minimumInputLength: 2,
  },
  // configurations for othe objects(business partners, locations) can be added here
};

export default configurations;
